





























































































































import { Vue, Component } from 'vue-property-decorator'
import { fanpageUrl, facebookUrl, zaloUrl } from '../../config'

@Component
export default class Policy extends Vue {
	private fanpageUrl = fanpageUrl
	private facebookUrl = facebookUrl
	private zaloUrl = zaloUrl
}
